<template>
  <div>
    <b-button
      class="parsing-class"
      v-b-popover.hover.top=""
      title="答题解析"
      v-if="variation == 1"
    >
      <span
        class="iconfont icon-a-biaodancaidanmingdan"
        @click="clickExplain()"
      ></span>
    </b-button>

    <span
      v-else
      class="iconfont icon-a-biaodancaidanmingdan"
      @click="clickExplain()"
    ></span>
    <b-modal
      centered
      hide-footer
      v-model="parsingShow"
      body-class="explain-body"
      dialog-class="explain-dialog"
      content-class="explain-content"
      header-class="explain-header"
      title="答题解析"
    >
      <span v-html="dataParsing"></span
    ></b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parsingShow: false,
    };
  },
  props: {
    dataParsing: {
      default: "",
    },
    variation: {
      default: "",
    },
  },
  methods: {
    clickExplain() {
      this.parsingShow = !this.parsingShow;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-a-biaodancaidanmingdan {
  color: #2cb774;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
</style>
<style lang="scss">
.parsing-class,
.parsing-class:hover,
.parsing-class:focus {
  border: none;
  background: none;
  padding: 0;
  box-shadow: none;
}
.parsing-class:not(:disabled):not(.disabled):active {
  border: none;
  background: none;
  padding: 0;
}
.parsing-class:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.explain-dialog {
  max-width: 70rem;
}
.explain-content {
  height: 45rem;
  position: absolute;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.explain-body {
  overflow-x: hidden;
  height: 45rem;
}
.explain-header {
  background-color: #2cb774;
  color: #fff;
  .close:hover,
  .close {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    text-shadow: none;
  }
}
</style>