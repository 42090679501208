var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mockTestContainer"},[_c('b-row',[(_vm.examQuestion.statement)?_c('b-col',{staticClass:"question-statement",attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"question-number"},[_vm._v(" "+_vm._s(_vm.examQuestion.variation + ". ")+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.examQuestion.statement ? _vm.examQuestion.statement : '')}})]),_c('ParsingModal',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.$route.meta.startReportImg ||
          _vm.$route.meta.smallReportImg ||
          _vm.$route.meta.bigReportImg ||
          _vm.$route.meta.mockReportImg
        ),expression:"\n          $route.meta.startReportImg ||\n          $route.meta.smallReportImg ||\n          $route.meta.bigReportImg ||\n          $route.meta.mockReportImg\n        "}],attrs:{"dataParsing":_vm.examQuestion.standardAnswer,"variation":_vm.examQuestion.variation}})],1):_vm._e(),(!_vm.examQuestion.statement)?_c('span',{staticStyle:{"margin":"0 -1.5rem 0 1.5rem","font-weight":"bold"}},[_vm._v(_vm._s(_vm.examQuestion.variation + ". "))]):_vm._e(),(!_vm.showTheAnswer)?_c('b-col',{staticClass:"question-Options"},[_c('b-form-radio-group',{staticStyle:{"display":"flex","flex-wrap":"wrap"},attrs:{"size":"lg"},on:{"change":function (checked) {
            _vm.changeRadio(checked, _vm.examQuestion.questionId);
          }},model:{value:(_vm.userAnswer),callback:function ($$v) {_vm.userAnswer=$$v},expression:"userAnswer"}},_vm._l((_vm.examQuestion.options),function(option){return _c('b-col',{key:option.value,staticClass:"question-option",attrs:{"md":"6","cols":"12"}},[_c('b-form-radio',{staticClass:"enter-radio",attrs:{"value":option.value}},[_c('span',[_vm._v(_vm._s(option.value + ". " + option.statement))])])],1)}),1)],1):_c('b-col',{staticClass:"mock-question-option"},[_c('b-form-radio-group',{staticStyle:{"display":"flex","flex-wrap":"wrap"},attrs:{"size":"lg"},on:{"change":function (checked) {
            _vm.changeRadio(checked, _vm.examQuestion.questionId);
          }},model:{value:(_vm.examQuestion.userAnswerKey),callback:function ($$v) {_vm.$set(_vm.examQuestion, "userAnswerKey", $$v)},expression:"examQuestion.userAnswerKey"}},_vm._l((_vm.examQuestion.options),function(option){return _c('b-col',{key:option.value,staticClass:"question-option",attrs:{"md":"6","cols":"12"}},[_c('b-form-radio',{class:[
              option.value === _vm.examQuestion.userAnswerKey &&
              option.value === _vm.examQuestion.standardAnswerKey
                ? 'correct-radio'
                : option.value !== _vm.examQuestion.userAnswerKey &&
                  option.value !== _vm.examQuestion.userAnswerKey
                ? ''
                : 'error-radio' ],attrs:{"disabled":_vm.showTheAnswer,"value":option.value}}),_c('span',{class:[
              option.value === _vm.examQuestion.standardAnswerKey
                ? 'correct-text'
                : option.value !== _vm.examQuestion.userAnswerKey &&
                  option.value !== _vm.examQuestion.userAnswerKey
                ? ''
                : 'error-text' ]},[_c('span',[_vm._v(_vm._s(option.value + "."))]),_c('span',{domProps:{"innerHTML":_vm._s(option.statement)}})]),(option.value === _vm.examQuestion.standardAnswerKey)?_c('span',{staticClass:"iconfont icon-duihao"}):_vm._e()],1)}),1)],1),_c('ParsingModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.parseShow()),expression:"parseShow()"}],attrs:{"dataParsing":_vm.examQuestion.standardAnswer,"variation":_vm.examQuestion.variation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }