<template>
  <b-container class="mockTestContainer">
    <b-row>
      <b-col class="question-statement" v-if="examQuestion.statement" cols="12">
        <div>
          <span class="question-number">
            {{ examQuestion.variation + ".  " }}&nbsp;&nbsp;
          </span>
          <div
            v-html="examQuestion.statement ? examQuestion.statement : ''"
          ></div>
        </div>
        <!-- startReportImg smallReportImg bigReportImg mockReportImg  是否是报告页面  题干-->
        <ParsingModal
          v-show="
            $route.meta.startReportImg ||
            $route.meta.smallReportImg ||
            $route.meta.bigReportImg ||
            $route.meta.mockReportImg
          "
          :dataParsing="examQuestion.standardAnswer"
          :variation="examQuestion.variation"
        ></ParsingModal>
        <!--          (examQuestion.statement ? examQuestion.statement : "")-->
        <!--        }}-->
      </b-col>
      <span
        style="margin: 0 -1.5rem 0 1.5rem; font-weight: bold"
        v-if="!examQuestion.statement"
        >{{ examQuestion.variation + ". " }}</span
      >
      <b-col class="question-Options" v-if="!showTheAnswer">
        <b-form-radio-group
          style="display: flex; flex-wrap: wrap"
          size="lg"
          @change="
            (checked) => {
              changeRadio(checked, examQuestion.questionId);
            }
          "
          v-model="userAnswer"
        >
          <b-col
            md="6"
            cols="12"
            v-for="option in examQuestion.options"
            :key="option.value"
            class="question-option"
          >
            <b-form-radio class="enter-radio" :value="option.value"
              ><span>{{
                option.value + ".  " + option.statement
              }}</span></b-form-radio
            >
          </b-col>
        </b-form-radio-group>
      </b-col>
      <b-col class="mock-question-option" v-else>
        <b-form-radio-group
          style="display: flex; flex-wrap: wrap"
          size="lg"
          @change="
            (checked) => {
              changeRadio(checked, examQuestion.questionId);
            }
          "
          v-model="examQuestion.userAnswerKey"
        >
          <b-col
            md="6"
            cols="12"
            v-for="option in examQuestion.options"
            :key="option.value"
            class="question-option"
          >
            <!--             correct-radio 对应正确选择框  correct-text 正确选择框后的文本-->
            <b-form-radio
              :class="[
                option.value === examQuestion.userAnswerKey &&
                option.value === examQuestion.standardAnswerKey
                  ? 'correct-radio'
                  : option.value !== examQuestion.userAnswerKey &&
                    option.value !== examQuestion.userAnswerKey
                  ? ''
                  : 'error-radio',
              ]"
              :disabled="showTheAnswer"
              :value="option.value"
            ></b-form-radio>
            <span
              :class="[
                option.value === examQuestion.standardAnswerKey
                  ? 'correct-text'
                  : option.value !== examQuestion.userAnswerKey &&
                    option.value !== examQuestion.userAnswerKey
                  ? ''
                  : 'error-text',
              ]"
              ><span>{{ option.value + "." }}</span
              ><span v-html="option.statement"></span
            ></span>

            <span
              v-if="option.value === examQuestion.standardAnswerKey"
              class="iconfont icon-duihao"
            ></span>
          </b-col>
        </b-form-radio-group>
      </b-col>
      <!-- startReportImg smallReportImg bigReportImg mockReportImg  是否是报告页面  选项-->
      <ParsingModal
        v-show="parseShow()"
        :dataParsing="examQuestion.standardAnswer"
        :variation="examQuestion.variation"
      ></ParsingModal>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import ParsingModal from "@/components/global-popup/global-parsing-modal.vue";
export default {
  data() {
    return {
      userAnswer: "",
    };
  },
  components: {
    ParsingModal,
  },
  computed: {
    ...mapState(["simulationExamQuestions", "userAnswers"]),
  },
  props: {
    examQuestion: {
      default: () => {
        return {};
      },
    },
    showTheAnswer: {
      type: Boolean,
      default: false,
    },
    setionId: {
      default: "",
    },
    sectionType: {
      default: "",
    },
    anser: {
      default: "",
    },
    index: {
      default: "",
    },
  },
  watch: {
    index: {
      handler(value) {
        try {
          if (this.userAnswers) {
            for (const a of this.userAnswers.answerSectionInputs[value]
              .answerQuestionInputs) {
              if (a.questionId === this.examQuestion.questionId) {
                this.userAnswer = a.answer;
                break;
              }
            }
          }
        } catch (e) {
          console.log("e", e);
        }

        console.log("监听数组传递", value, this.userAnswer);
      },
      immediate: true,
    },
  },
  mounted() {
    console.log("examQuestion", this.examQuestion);
  },
  methods: {
    changeRadio(checked, questionId) {
      this.$emit("changeRadio", checked, questionId, this.setionId);
    },
    parseShow() {
      if (
        this.$route.meta.startReportImg ||
        this.$route.meta.smallReportImg ||
        this.$route.meta.bigReportImg ||
        this.$route.meta.mockReportImg
      ) {
        if(this.sectionType == 3 || this.sectionType == 4 ){
          return true
        }
      } else {
        return false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-stament {
  line-height: 2;
}
.question-option {
  text-align: left;
  display: flex;
  padding-bottom: 0.5rem;
  .custom-control-inline {
    font-size: 1rem !important;
    line-height: 2 !important;
    margin: 0;
    padding: 0;
  }
}
.question-statement {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  line-height: 2;
  font-weight: bold;
  & > div:nth-child(1) {
    display: flex;
  }
}
.test-item .col-md-6 > span:nth-child(2) {
  line-height: 2;
  padding-right: 5px;
  display: flex;
}
.question-Options {
  padding-left: 3.8rem;
}
.question-number {
  padding-right: 4px;
}
.mock-question-option {
  padding-left: 3.7rem;
  .question-option {
    padding-bottom: 1.5rem;
  }
  .icon-duihao {
    color: #2cb774;
    line-height: 1.5;
    font-size: 18px;
    padding-left: 5px;
    font-weight: bold;
  }
}
</style>
<style lang="scss" >
.question-option {
  p {
    margin: 0;
  }
}
.correct-radio {
  .custom-control-label::before {
    background-color: #2cb774 !important;
    border-color: #2cb774 !important;
  }
}
.error-radio {
  .custom-control-label::before {
    background-color: #cc0000 !important;
    border-color: #cc0000 !important;
    font-weight: bold !important;
  }
}
.correct-text {
  color: #2cb774 !important;
  font-weight: bold !important;
}
.error-text {
  color: #cc0000 !important;
  font-weight: bold !important;
}
</style>
