<template>
  <SprintAnswerBackground >
    <b-row>
      <b-col class="text-left answer-title">
        <span>语法与功能：名词</span>
      </b-col>
    </b-row>
    <b-row style="height:80%">
      <b-col>
        <div>
          <select-question
            v-for="question in examQuestion.simulationExamQuestions"
            :examQuestion="question"
            :key="question.questionId"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right">
        <GlobalButton
          buttonType="round"
          buttonText="提交答案"
          @afterClick="submitAnswer"
        ></GlobalButton>
        <GlobalButton
          buttonType="round"
          buttonText="下一题"
          @afterClick="nextQuestion"
        ></GlobalButton>
      </b-col>
    </b-row>
  </SprintAnswerBackground>
</template>

<script>
import SprintAnswerBackground from "@/components/backgroung/sprint-answer-background.vue";
import SelectQuestion from "@/views/english-mock/components/SelectQuestion.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
export default {
  data() {
    return {
      examQuestion: {
        sectionScore: 0,
        plyTkSectionId: 2,
        description: "",
        stemStatement: "",
        simulationExamQuestions: [
          {
            standardAnswerKey: "",
            questionId: "7c90cfa1-1df2-4805-b363-f6222fd1df7d",
            statement:
              '<p><span lang="EN-US">Mr. Jackson is a popular writer, and we all like reading&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;books.</span></p>',
            variation: 21,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "his",
              },
              {
                value: "B",
                statement: "her",
              },
              {
                value: "C",
                statement: "their",
              },
              {
                value: "D",
                statement: "your",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
        ],
      },
    };
  },
  methods:{
      submitAnswer(){
          this.$router.replace({
              name:'SmallModuleGrammarTestEnd'
          })
      },
      nextQuestion(){
        
      }
  },
  components: {
    SprintAnswerBackground,
    SelectQuestion,
    GlobalButton,
  },
};
</script>

<style scoped lang="scss">
.text-left {
  text-align: left;
}
.answer-title {
  padding: 15px;
  color: #2CB774;
}
</style>